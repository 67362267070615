import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import infiniteScroll from "vue-infinite-scroll";
import _phoneFlag from './utils/rem.js'
import { Loading } from 'element-ui';

import Toast from './components/m/Toast'

Vue.use(Loading.directive);
Vue.config.productionTip = false
Vue.use(infiniteScroll);
Vue.prototype.$phoneFlag = _phoneFlag.phoneFlag;
Vue.prototype.$toast = Toast //挂载到vue原型上
import "./axios/axios"
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

/*pc端自适应有什么要求
* 文字要自适应吗
* 容器宽度
* 需要留白吗*/

// pc端页面设计图是1503宽度，我是给他定宽1200px然后两边留白居中还是做一个百分比


//荣誉榜  导航  是否不固定  （不清楚）  这里需要处理小三角的动画

//活动博览没有简要内容

//附件下载 ×

//页面离开时  将定时器关闭
