<template>
  <div id="app">
    <Header @search_in="SearchResult_is" @login_in="LogibPanel" :info="user" :title="header_title"></Header>
    <transition enter-active-class="animate__animated animate__fadeInDown" leave-active-class="animate__animated animate__fadeOutUp">
      <SearchResult v-if="SearchResult_flag" :search="search_text" @search_in="SearchResult_is"></SearchResult>
    </transition>
    <keep-alive include="home,press-center,honors,event-expo,cinecism,film-week,trends,information,m_list">
      <router-view @header_title_event="setTitle" />
    </keep-alive>
    <Footer></Footer>
<!--    <el-dialog
        v-if="!$phoneFlag"
        title="短信登录"
        :visible.sync="login"
        width="400px"
        center>
      <el-input
          placeholder="请输入手机号码"
          v-model.number="phone"
          clearable>
      </el-input>
      <div class="code">
        <el-input
            placeholder="请输入验证码"
            v-model ="code.content"
            clearable>
        </el-input>
        <el-button @click="getCode" :disabled="code.getCodeAbled" :loading="code.getCodeLoad">{{code.tip}}</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setLogin">登 录</el-button>
      </span>
    </el-dialog>-->
  </div>
</template>

<style lang="scss">
@import '~animate.css';
@media all{
  html{

  }
  body{
    padding: 0;
    margin: 0;
    font-size: 16px;
  }
  /**{
    transition: all 0.1s;
  }*/
  .app{
    width: 100%;
  }

  // 滚动条宽度
  body::-webkit-scrollbar{
    width: 5px;
  }

  /* 定义滚动条轨道 */
  body::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #FFF;
  }

  /* 滑块 */
  body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #0374E6 ;
    background-color: #AAA;
  }
}

@media all and (min-width: 1200px) {
  .container {
    width: 1170px!important;
    margin: 0 auto;
  }
}
@media all and (min-width: 768px) and (max-width: 1199px){
  .container {
    width: 100% !important;
  }
}

@media all and (max-width: 767px){
  body{
    padding: 0!important;
  }
}

</style>
<style scoped lang="scss">
@media all and (min-width: 767px){
  #app{
    ::v-deep .el-dialog__wrapper{
      .el-dialog{
        width: 400px;
        margin-top: 30vh!important;
        border-radius: 18px;
        .el-dialog__header{
          .el-dialog__title{
            font-size: 19px;

            font-weight: bold;
            color: #0F4071;
            line-height: 40px!important;
          }
          .el-dialog__headerbtn{
            top: 14px!important;
          }
        }
        .el-dialog__body{
          padding-top: 5px!important;
          padding-bottom: 39px!important;
          .el-input{
            width: 90%!important;
            margin: 0 auto;
            display: block!important;
            height: 42px;
            .el-input__inner{
              border-radius: 20px;
              font-size: 14px;

              font-weight: 500;
            }
          }
          .code{
            margin-top: 25px;
            position: relative;
            .el-input{
              .el-input__inner{
                padding-right: 125px!important;
              }
            }
            .is-loading{
              pointer-events: auto;
            }
            .el-button{
              position: absolute;
              right: 30px;
              top: 8px;
              height: 26px;
              width: 97px;
              border-radius: 33px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-image: linear-gradient(to left, #0373E6, #219CFC);
              padding: 0px!important;
              span{
                font-size: 14px;

                font-weight: 500;
                color: #FFFFFF;
              }
            }
          }
        }
        .el-dialog__footer{
          padding-bottom: 47px!important;
          .el-button{
            width: 90%!important;
            height: 42px;
            border-radius: 33px;
            background-image: linear-gradient(to left, #0373E6, #219CFC);
            padding: 0;
            span{
              font-size: 19px;

              font-weight: 800;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
}

</style>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import SearchResult from "@/components/SearchResult";
import {Button, Dialog, Input,Message  } from "element-ui";
import {Login, SendCode, Wxconfig} from "@/axios/api";
export default {
  components: {
    SearchResult,
    Footer,
    Header,
    [Dialog.name]:Dialog,
    [Button.name]:Button,
    [Input.name]:Input,
    [Message .name]:Message
  },
  data(){
    return{
      SearchResult_flag:false,
      login:false,
      phone:'',
      code:{
        content:'',
        tip:'获取验证码',
        getCodeAbled:false,
        getCodeLoad:false,
        djs:60
      },
      search_text:'',
      user:false,
      header_title:'',
      config:''
    }
  },
  created() {

  },
  methods:{
    SearchResult_is(v){
      if(v.length==0){
        this.SearchResult_flag=false;
      }else{
        this.search_text = v;
        this.SearchResult_flag=true;
      }
    },
    LogibPanel(v){
      this.login = v
    },
    getCode(){
      //获取验证码 进入loading状态
      //获取成功返回数据 关闭loading状态 打开禁用 开始1分钟倒计时
      //倒计时结束 关闭禁用
      SendCode({
        mobile:this.phone
      }).then(res=>{
        console.log(res)
        if(res.state==1){
          Message.success('验证码发送成功')
          let Interval = null;
          this.code.tip = '发送中'
          this.code.getCodeLoad = true
          setTimeout(()=>{
            this.code.getCodeLoad = false
            this.code.getCodeAbled = true
            this.code.tip = `${this.code.djs}秒后获取`
            Interval = setInterval(()=>{
              if(this.code.djs>0){
                this.code.djs--;
                this.code.tip = `${this.code.djs}秒后获取`
              }else{
                this.code.djs = 60;
                this.code.getCodeAbled = false
                this.code.tip = '获取验证码'
                clearInterval(Interval)
              }
            },1000)
          },1000)
        }else {
          Message.error(res.msg);
        }
      }).catch(err=>{
        console.log(err)
        Message.error('网络出现异常，请稍后再试');
      })

    },
    setLogin(){
      Login({
        mobile:this.phone,
        smscode:this.code.content
      }).then(res=>{
        console.log(res)
        if(res.state==1){
          Message.success('登录成功')
          this.user=true
          this.login = false;
          this.phone='';
          this.code.content='';
          localStorage.setItem('token',res.data)
          this.code.djs=0
        }else{
          Message.error('验证码错误，请检查后再提交');
        }
      }).catch(err=>{
        console.log(err)
        Message.error('网络出现异常，请稍后再试');
      })
    },
    setTitle(v){
      this.header_title=v;
    },
  }
}
</script>
