import axios from './http'
/*https://blog.51cto.com/jackiehao/5089232*/

/*验证token是否过期*/
export function TokenOut(params){
    return axios.post("/common/api/checkout",params)
}
/*微信配置*/
export function Wxconfig(params){
    return axios.post("/common/api/jssdkSign",params)
}
/*原网址跳现网址*/
export function OldJump(params){
    return axios.post("/common/api/jump",params)
}
/*板块位置*/
export function AdminPlate(params){
    return axios.post("/admin/api/plate",params)
}
/*新闻列表*/
export function NewsLists(params){
    return axios.post("/news/api/lists",params)
}
/*电影动态列表*/
export function NewsTrendsLists(params){
    return axios.post("/news/api/trends",params)
}
/*电影资讯列表*/
export function NewsInforsLists(params){
    return axios.post("/news/api/infors",params)
}
/*地方频道导航*/
export function ChannelLists(params){
    return axios.post("/channel/api/place",params)
}
/*地方列表*/
export function ChannelMainLists(params){
    return axios.post("/channel/api/lists",params)
}
/*地方详情*/
export function ChannelShow(params){
    return axios.post("/channel/api/show",params)
}
/*电影类别*/
export function NewsType(params){
    return axios.post("/news/api/type",params)
}
/*新闻详情*/
export function NewsShow(params){
    return axios.post("/news/api/show",params)
}

/*轮播图*/
export function GetSwiper(params){
    return axios.post("/common/api/commercial",params)
}

/*荣誉位*/
export function HonorPlace(params){
    return axios.post("/honor/api/place",params)
}

/*荣誉列表*/
export function HonorList(params){
    return axios.post("/honor/api/lists",params)
}

/*荣誉榜详情*/
export function HonorShow(params){
    return axios.post("/honor/api/show",params)
}

/*活动博览位*/
export function ActivityPlace(params){
    return axios.post("/activity/api/place",params)
}

/*活动博览列表*/
export function ActivityLists(params){
    return axios.post("/activity/api/lists",params)
}

/*活动博览详情*/
export function ActivityShow(params){
    return axios.post("/activity/api/show",params)
}

/*影评列表*/
export function CommentsLists(params){
    return axios.post("/comments/api/lists",params)
}

/*影评详情*/
export function CommentsShow(params){
    return axios.post("/comments/api/show",params)
}

/*电影周列表*/
export function FilmWeeklists(params){
    return axios.post("/filmweek/api/lists",params)
}

/*展映电影大图*/
export function FilmExhibitionTOp(params){
    return axios.post("/filmweek/api/top",params)
}

/*电影周详情*/
export function FilmWeekShow(params){
    return axios.post("/filmweek/api/show",params)
}

/*全站搜索*/
export function Search(params){
    return axios.post("/common/api/search",params)
}

/*接收验证码*/
export function SendCode(params){
    return axios.post("/common/api/sendCode",params)
}
/*登录*/
export function Login(params){
    return axios.post("/member/api/login",params)
}
/*添加评论*/
export function AddDiscuss(params){
    return axios.post("/discuss/api/discuss",params)
}
/*获取评论*/
export function DiscussLists(params){
    return axios.post("/discuss/api/lists",params)
}
/*猫眼电影*/

export function Maoyan(params){
    return axios.post("/common/api/getData",params)
}

/*互动投票*/

/*栏目位*/
export function VotePlace(params){
    return axios.post("/vote/api/place",params)
}

//打榜
export function FilmSetting(params){
    return axios.post("/film/api/setting",params)
}

//获取列表
export function FilmLists(params){
    return axios.post("/film/api/lists",params)
}

//获得视频列表
export function VoteLists(params){
    return axios.post("/vote/api/lists",params)
}
//视频详情
export function VoteShow(params){
    return axios.post("/vote/api/show",params)
}
//视频投票
export function VoteVote(params){
    return axios.post("/vote/api/vote",params)
}
//视频总体数据
export function VoteGetdata(params){
    return axios.post("/vote/api/getdata",params)
}

/*活动报名*/
//活动列表
export function ApplyLists(params){
    return axios.post("/apply/api/lists",params)
}
//活动详情
export function ApplyShow(params){
    return axios.post("/apply/api/show",params)
}
//活动报名
export function ApplyApply(params){
    return axios.post("/apply/api/apply",params)
}

/*专题栏目*/
//专题列表
export function ProjectShow(params){
    return axios.post("/project/api/show",params)
}
//单项列表
export function ProjectLists(params){
    return axios.post("/project/api/lists",params)
}
//pc端全部展示
export function ProjectAll(params){
    return axios.post("/project/api/project",params)
}
