import {Wxconfig} from "@/axios/api";
import md5 from 'js-md5'
const wxChat = {
    wxLogin(share) {
        let ua = navigator.userAgent.toLowerCase();
        if(ua.indexOf('micromessenger') == -1){
            console.log('不是微信浏览器')
            return
        }
        let ts = Math.round(new Date().getTime() / 1000).toString();
        let appkey = "0db0c152d0c4d81875709a8efb59c71c";
        let sign =
            "timestamp=" + ts + "&url=" + window.location.href + "@" + appkey;
        Wxconfig({
            url: window.location.href,
            timestamp: ts,
            signature: this.twiceMD5(sign),
        }).then(res=>{
            let config = res.data;
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: config.appId, // 必填，公众号的唯一标识
                timestamp: config.timestamp, // 必填，生成签名的时间戳
                nonceStr: config.nonceStr, // 必填，生成签名的随机串
                signature: config.signature, // 必填，签名
                jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
            });
            this.shareEvent(share)
            wx.ready(() => {
                console.log('验证成功')
            })
            wx.error(() => {
                console.log('验证失败')
            })
        }).catch(err=>{
            console.log('微信验证错误',err)
        })
    },
    twiceMD5(str) {
        let a = md5(str);
        let b = md5(a);
        return b;
    },
    // 微信分享
    shareEvent(share) {
        console.log('设置的分享内容',share)
        wx.ready(()=>{
            //需在用户可能点击分享按钮前就先调用
            wx.updateAppMessageShareData({
                title: share.title || window.document.title, // 分享标题
                desc: share.desc || '', // 分享描述
                link: share.link || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: share.imgUrl || `${location.origin}/share.png`, // 分享图标
                success: function () {
                    // 设置成功
                    console.log('分享设置成功')
                },
            });
            wx.updateTimelineShareData({
                title: share.title || window.document.title, // 分享标题
                link: share.link || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: share.imgUrl || `${location.origin}/share.png`, // 分享图标
                success: function () {
                    // 设置成功
                    console.log('分享设置成功')
                },
            });
        })
    }
}
export default wxChat
