import Vue from 'vue'
import VueRouter from 'vue-router'
import wxChat from "@/utils/wxShare";

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/index' },
  {
    path: '/index',
    name: 'home',
    component: () => import('../views/home'),meta: {title:"瞄电影"}
  },
  {
    path: '/press-center',
    name: 'press-center',
    component: () => import('../views/press-center'),meta: {title:"新闻中心"}
  },
  {
    path: '/honors',
    name: 'honors',
    component: () => import('../views/honors'),meta: {title:"荣誉榜"}
  },
  {
    path: '/event-expo',
    name: 'event-expo',
    component: () => import('../views/event-expo'),meta: {title:"有活动"}
  },
  {
    path: '/cinecism/:der',
    name: 'cinecism-der',
    component: () => import('../views/cinecism'),meta: {title:"新闻中心"}
  },
  {
    path: '/cinecism',
    name: 'cinecism',
    component: () => import('../views/cinecism'),meta: {title:"锐影评"}
  },
  {
    path: '/film-week',
    name: 'film-week',
    component: () => import('../views/film-week-ifarm'),meta: {title:"青年电影周"}
  },
  {
    path: '/details/:show/:id',
    name: 'my_details',
    component: () => import('../views/details')
  },
  {
    path: '/trends',
    name: 'trends',
    component: () => import('../views/trends'),meta: {title:"浙江电影动态"}
  },
  {
    path: '/information',
    name: 'information',
    component: () => import('../views/information'),meta: {title:"浙江电影资讯"}
  },
  {
    path: '/channel',
    name: 'channel',
    component: () => import('../views/channel-list'),meta: {title:"地方频道"}
  },
  {
    path: '/list/:name',
    name: 'm_list',
    component: ()=>import('../views/m_list')
  },
  {
    path: '/vote',
    name: 'vote',
    component: ()=>import('../views/m_interactive-voting'),
    children:[
      {
        path: '',
        // redirect:'/vote/hits' //根据返回的栏目 自动跳转到第一个栏目地址
      },
      {
        path: 'hits',
        name:'vote_hot',
        component: ()=>import('../views/m_vote-hot'),meta: {title:"互动投票"}
      },
      {
        path: 'view',
        name:'vote_see',
        component: ()=>import('../views/m_vote-see'),meta: {title:"互动投票"}
      },
      {
        path: 'video',
        name:'vote_match',
        component: ()=>import('../views/m_vote-match'),meta: {title:"互动投票"}
      },
      {
        path: 'apply',
        name:'vote_activity',
        component: ()=>import('../views/m_vote-activity'),meta: {title:"互动投票"}
      }
    ]
  },
  {
    path: '/vote-main',
    name: 'm_main',
    component: ()=>import('../views/m_vote-main'),meta: {title:"投票详情"}
  },
  {
    path: '/activity_main',
    name: 'activity_main',
    component: ()=>import('../views/m_vote_activity_main'),meta: {title:"互动投票"}
  },
  {
    path: '/activity_enlist',
    name: 'activity_enlist',
    component: ()=>import('../views/m_vote_activity_enlist'),meta: {title:"报名"}
  },
    /*中转页面 获得文章id 跳转到文章详情*/
  {
    path: '/news.html',
    name: 'news',
    component: ()=>import('../views/details')
  },
  {
    path: '/webDetails/news',
    name: 'webDetails',
    component: ()=>import('../views/details')
  },
  {
    path: '/more_nav',
    name: 'more_nav',
    component: ()=>import('../views/m_more_nav'),meta: {title:"更多"}
  },
  //专题文章
  {
    path: '/special',
    name: 'special',
    component: ()=>import('../views/special'),meta: {title:" "}
  },
  //移动端专题
  {
    path: '/mspecial',
    name: 'mspecial',
    component: ()=>import('../views/m_special'),meta: {title:" "}
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
    wxChat.wxLogin({})
  }
  next()
})

export default router
