// rem等比适配配置文件
// 设置 rem 函数
let maxWidth ;
let phoneFlag = false;
function setRem() {
    maxWidth = document.documentElement.clientWidth;
    if(maxWidth < 768){
        phoneFlag = true
        document.documentElement.style.fontSize = ((maxWidth / 750) * 16) + 'px';
    }
}
// 初始化
setRem();
// 改变浏览器窗口大小时重新设置 rem
window.onresize = function() {
    setRem();
};

export default {
    phoneFlag
}


