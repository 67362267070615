<template>
  <div class="search_result">
<!--    <i class="el-icon-circle-close" @click="$emit('search_in','')" v-if="$phoneFlag"></i>-->
    <div class="item" v-for="v in list" @click="goToArticle(v.moudle,v.id,v.project)">
      <div class="title">{{ v.title }}</div>
      <div class="time">{{ v.datetime }}</div>
    </div>
  </div>
</template>

<script>
import {Search} from "@/axios/api";

export default {
  name: "SearchResult",
  created() {
    Search({
      keyword:this.search
    }).then(res=>{
      if(res.state==1){
        this.list = res.data
      }else{
        this.list=[{title:'未找到相关文章'}]
      }
    }).catch(err=>{
      console.log(err)
    })
  },
  props:{
    search:String
  },
  watch:{
    search(){
      Search({
        keyword:this.search
      }).then(res=>{
        if(res.state==1){
          this.list = res.data
        }else{
          this.list=[{title:'未找到相关文章'}]
        }
      }).catch(err=>{
        console.log(err)
      })
    }
  },
  data(){
    return{
      list:''
    }
  },
  methods:{
    goToArticle(show,id,project){
      if(project!=0){
        if(this.$phoneFlag){
          this.$router.push({name:"mspecial",query:{show,id}})
        }else{
          this.$router.push({name:"special",query:{show,id}})
        }
      }else if(id){
        this.$router.push({name:"my_details",params:{show,id}})
        this.$emit('search_in','')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.search_result{
  width: 524px;
  height: 398px;
  background: #FFFFFF;
  box-shadow: 0px 5px 10px 2px rgba(0,0,0,0.15);
  border-radius: 0px 0px 8px 8px;
  position: absolute;
  left: calc((100% - 554px) / 2);
  top: 72px;
  z-index: 99999;
  overflow-y: auto;
  /*.el-icon-circle-close{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 3rem;
    color: #444444;
  }*/
  .item{
    height: 59px;
    width: 87%;
    margin: 0 auto;
    border-bottom: 1px solid #F2F2F2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    cursor: pointer;
    &:first-child{
      margin-top: 29px;
    }
    &:last-child{
      margin-bottom: 25px;
    }
    .title{
      font-size: 13px;

      font-weight: 500;
      color: #39444B;
      line-height: 15px;
    }
    .time{
      font-size: 13px;

      font-weight: 500;
      color: #9D9D9D;
      line-height: 15px;
    }
  }
}
@media all and (max-width: 1199px) and (min-width: 992px){
  .search_result{
    left: calc((100% - 520px) / 2);
  }
}
@media all and (min-width: 768px) and (max-width: 880px){
  .search_result{
    width: 378px;
    height: 327px;
    left: calc((100% - 447px) / 2);
  }
}
@media all and (min-width: 881px) and (max-width: 991px){
  .search_result{
    width: 378px;
    height: 327px;
    left: calc((100% - 466px) / 2);
  }
}
@media all and (max-width:766px){
  .search_result{
    width: 96%;
    left: 2%;
    top: 5.83rem;
  }
}
</style>
