import axios from 'axios'
import qs from 'qs'
import router from "../router";
//1. 创建新的axios实例，
const _axios = axios.create({
    baseURL: 'https://test.zjfilm.cn/',
})

// 2.请求拦截器
_axios.interceptors.request.use(require=>{
    //发出请求拦截
    require.data.timestamp=Math.round(new Date().getTime() / 1000);
    require.data = qs.stringify(require.data);
    return require
},error=>{
    return Promise.reject(error);
})


// 3.响应拦截器
_axios.interceptors.response.use(response=>{
    //返回结果拦截
    return response.data
},error=>{
    return Promise.reject(error)
})


export default _axios
