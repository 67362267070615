<template>
  <div class="container" v-if="!$phoneFlag">
    <div class="footer">
      <div class="row row-no-gutters">
        <div class="footer_l col-lg-3 col-md-2">
          <img src="../assets/footerLogo.png" alt="">
        </div>
        <div class="footer_c col-lg-7 col-md-8">
          <div class="function">
            <a href="#">版权声明</a>
            <a href="#">隐私政策</a>
            <a href="#">影评投稿</a>
            <a href="#">APP下载</a>
          </div>
          <div class="others">
            <span>钱江晚报©版权所有 2019-2020</span><span>浙CP备09055608号-19</span><span>浙公网安备 33010302003486号</span>
          </div>
        </div>
        <div class="footer_r col-lg-2 col-md-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="scss" scoped>
  .footer{
    width: 100%;
    margin: 0 auto;
    height: 170px;
    clear: both;
    .row{
      height: 100%;
    }
    .footer_l{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      img{
        height: 60%;
      }
    }
    .footer_c{
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .function{
        height: 24%;
        a{
          text-decoration: none;
          color: #26306A;
          float: left;
          margin-right: 12px;
          font-size: 12px;
        }
      }
      .others{
        color: #26306A;
        span{
          float: left;
          margin-right: 12px;
          font-size: 12px;
        }
        span:last-of-type{
          padding-left: 22px;
          background: url("../assets/71f8d5d-2e18440a 1.png") 0 0/17px 18px no-repeat;
        }
      }
    }
  }
  @media all and (max-width: 991px) and (min-width: 768px){
    .footer{
      height: auto;
      .footer_l{
        margin-bottom: 40px;
      }
      .footer_c{
        justify-content: start;
        height: auto;
        padding-bottom: 30px;
        .function{
          height: 40px;
        }
      }
    }
  }
</style>